html,
body,
#root {
  max-height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: #0b0b0b;
  color: #d9d9d9;
}

* {
  font-family: 'Bebas Neue', 'Montserrat', sans-serif;
  letter-spacing: 1px;
}
